import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import search from "../../assets/images/search.svg";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  saveSearchResult,
  saveSearchWord,
  saveSpellcorrection,
  saveSearchResultAuthDocs,
  setEquipment,
  setTypes,
  setTotalDoc,
  setActivePage,
  addfilter,
  saveQAContent,
  setToaster,
  setSearchId
} from "../../redux/actions";
import { Loader } from "semantic-ui-react";
import KeycloakUserService from "../../keycloak/KeycloakUserService.js";
import { useNavigate } from "react-router";
import { searchApi } from "../apiCall";
import vannord_logo from "../../assets/images/vanoord.png";
import { appInsights } from "../../utils/appInsights.js";

const Search = (props) => {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [noResult, setNoResult] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const websocketRef = useRef(null);
  const currentQueryRef = useRef("");
  const suggestionsContainerRef = useRef(null);
  const searchInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    props.setActivePage(1);
  }, []);

  // Add click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showSuggestions &&
        suggestionsContainerRef.current && 
        !suggestionsContainerRef.current.contains(event.target) &&
        searchInputRef.current && 
        !searchInputRef.current.contains(event.target)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSuggestions]);

  useEffect(() => {
    const NLP_URL = process.env.REACT_APP_NLP_BASE_URL;
    
    const isHTTP = NLP_URL.startsWith("http://");
    const NLP_IP = isHTTP ? NLP_URL.slice(7) : NLP_URL.slice(8);
    const WEBSOCKET_URL = `${isHTTP ? "ws":"wss"}://${NLP_IP}/autocomplete/ws/autocomplete`;

    websocketRef.current = new WebSocket(WEBSOCKET_URL);

    websocketRef.current.onmessage = (event) => {
      let suggestion = event.data;
      suggestion = suggestion.slice(6); // remove the "data:" prefix
      if (suggestion.startsWith(currentQueryRef.current.toLowerCase())) {
        setSuggestions((prev) => [...new Set([...prev, suggestion])]);
      }
    };

    return () => {
      if (websocketRef.current) {
        websocketRef.current.close();
      }
    };
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    setShowSuggestions(true);
    setNoResult(false);
    currentQueryRef.current = value;

    // Clear previous suggestions
    setSuggestions([]);

    if (value.length > 0) {
      if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
        websocketRef.current.send(value.toLowerCase());
      }
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    setShowSuggestions(false);
    props.setSearchId(uuidv4());
  };

  const renderSuggestion = (suggestion) => {
    const matchIndex = suggestion.toLowerCase().indexOf(query.toLowerCase());
    if (matchIndex === -1) return suggestion;
  
    const before = suggestion.slice(0, matchIndex);
    const matchText = suggestion.slice(matchIndex, matchIndex + query.length);
    const after = suggestion.slice(matchIndex + query.length);
    
    // Match the case of the query
    const matchedPart = query === query.toUpperCase() 
      ? matchText.toUpperCase() 
      : query === query.toLowerCase() 
        ? matchText.toLowerCase()
        : matchText;
  
    return (
      <>
        <span className="font-bold">{before}</span>
        <span className="font-normal">{matchedPart}</span>
        <span className="font-bold">{after}</span>
      </>
    );
  };

  function handleSessionOut() {
    if (KeycloakUserService.isTokenExpired()) {
      navigate("/");
      localStorage.clear();
      props.saveSearchWord("");
      window.location.reload(false);
      KeycloakUserService.doLogout();
    }
  }

  const getDocs = async (e) => {
    // navigate("/searchResult");
    props.saveSearchWord(query);
    props.saveQAContent("");
    let filterData = [];
    props.addfilter(JSON.stringify({}));
    if (query !== "") {
      props.saveSearchWord(query);
      handleSessionOut();
      e.preventDefault();
      setLoading(true);
      var queryToExecute = {
        q: query,
        rows: 10,
        start: 0,
        filter: filterData,
        searchId: props.searchId,
      };

      try {
        const res = await searchApi(queryToExecute);
        props.saveSearchResult(JSON.stringify(res.data.response.docs));
        res.data.response.authDocs?.length > 0
          ? props.saveSearchResultAuthDocs(
              JSON.stringify(res.data.response.authDocs)
            )
          : props.saveSearchResultAuthDocs("");
        if (res.data.equipment !== undefined) {
          props.setEquipment(res.data.equipment);
        }
        if (res.data.type !== undefined) {
          console.log("res.data.type", res.data.type);
          props.setTypes(res.data.type);
        }
        if (res.data.spellcheck !== undefined) {
          props.saveSpellcorrection(res.data.spellcheck);
        }
        props.setTotalDoc(res.data.response.numFound);
        props.setActivePage(1);
        navigate("/searchResult");
      } catch (error) {
        props.setToaster(true);
        if (
          error.response.data === "Access denied" ||
          error.response.status === 403
        ) {
          KeycloakUserService.doLogout();
        }
      } finally {
        // props.ableLoading(false);
      }
    }; 
  };

  return (
    <>
      <div className="flex flex-col justify-center content-center items-center pb-20 mt-[-1rem]">
        <div className="font-bold text-[20px] md:text-[42px] leading-[22px] md:leading-[72px] text-[#0971CE] flex flex-col items-center font-inter">
          <div>
            <img
              className="h-10 mr-3"
              src={vannord_logo}
              alt="vanoord_marine_ingeunity logo"
            />
          </div>
          <div>xIntellisearch</div>
        </div>
        <form className="relative">
          <div className="mb-5 ml-[0.2rem] md:ml-o relative">
            <span className="material-symbols-outlined relative top-[0.6rem] md:top-2 left-7 md:left-10 z-10">
              <img
                className="w-3 md:w-5 mb-2.5 material-symbols-outlined"
                src={search}
                alt="search logo"
              />
            </span>
            <input
              ref={searchInputRef}
              className="w-[350px] md:w-[564px] h-[22px] md:h-[44px] text-[5] pl-10 md:pl-14 focus:outline-none rounded-[28px] border shadow-[0px_0px_5px_0px_rgba(0,0,0,0.30)] placeholder-gray-400 text-[7px] md:text-[14px]"
              name="input"
              value={query}
              onChange={handleInputChange}
              type="text"
              placeholder="Ask me anything. Describe your question for better results."
              title="Search"
            />
            {showSuggestions && suggestions.length > 0 && (
              <ul 
                ref={suggestionsContainerRef}
                className="absolute z-10 w-full bg-white border border-gray-300 rounded-b-lg rounded-2xl shadow-lg mt-1"
              >
                {suggestions.slice(0, 10).map((suggestion, index) => (
                  <li
                    key={index}
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-[7px] md:text-[14px]"
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {renderSuggestion(suggestion)}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="searchbutton">
            <button
              type="submit"
              className="bg-[#0971CE] h-[20px] md:h-[40px] w-[60px] md:w-[120px] text-[7px] md:text-[14px] rounded-[20px] text-white font-inter"
              onClick={getDocs}
            >
              Search
            </button>
          </div>
        </form>
        <div style={{ paddingTop: "5px" }}>
          {loading ? (
            <Loader active inline />
          ) : (
            <div className={`${noResult ? "" : "dummy-space"}`}></div>
          )}
        </div>
        {noResult && (
          <div
            style={{
              paddingTop: "5px",
              color: "gray",
              height: "2.28571429rem",
            }}
          >
            <p>No Search Result Found</p>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  searchedWord: state.searchedWord,
  pinnedSearches: state.pinnedSearches,
  searchId: state.searchId,
  spellcheck: state.spellcheck,
});

export default connect(mapStateToProps, {
  saveSearchResult,
  saveSearchWord,
  saveSpellcorrection,
  saveSearchResultAuthDocs,
  setEquipment,
  setTypes,
  setTotalDoc,
  setActivePage,
  addfilter,
  saveQAContent,
  setToaster,
  setSearchId
})(Search);